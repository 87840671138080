<template>
  <div>
    <b-form-group label="Search">
      <b-form-input v-model="search" placeholder="Search" :debounce="250"></b-form-input>
    </b-form-group>


		<template v-if="search && filteredItems.length === 0">
			<b-alert class="text-center" variant="primary" show>
					<h6 class="mb-0">No results found</h6>
			</b-alert>
		</template>

    <b-card-group columns>
			<import-card v-for="(item, index) in filteredItems" :key="index"
                   :title="item.name"
                   :query-list="item.queryList"
									 :mutation-create="item.mutationCreate"
									 :mutation-update="item.mutationUpdate"
									 :mutation-delete="item.mutationDelete"
									 :item-key="item.key"
									 :item-keys-to-delete="item.keysToDelete"
			/>
    </b-card-group>

<!--		<b-card-group columns>
			<import-card-cognito v-for="(item, index) in cognito" :key="index"
													 :title="item.name"
													 :query-list="item.queryList"
													 :mutation-create="item.mutationCreate"
													 :mutation-delete="item.mutationDelete"
													 :item-key="item.key"
			/>
		</b-card-group>-->

		<b-card-group columns>
			<import-card-cognito />
		</b-card-group>

		<b-card>
			<b-button @click="testCognito">Test Cognito</b-button>
		</b-card>



<!--    <template v-if="search">
      <hr>
			<b-card-group columns>
				<b-card v-for="(item, index) in dependentItems" :key="index" :title="item.name">
					<import-card :title="item.name"
											 :query-list="item.queryList"
											 :mutation-create="item.mutationCreate"
											 :mutation-update="item.mutationUpdate"
											 :item-key="item.key"
					/>
				</b-card>
			</b-card-group>
    </template>-->
  </div>
</template>

<script>
import {
  listApplications, createApplication, updateApplication, deleteApplication,
  listBuses, createBus, updateBus, deleteBus,
  listDistricts, createDistrict, updateDistrict, deleteDistrict,
  listDocuments, createDocument, updateDocument, deleteDocument,
  listDocumentCategories, createDocumentCategory, updateDocumentCategory, deleteDocumentCategory,
  listEnsembles, createEnsemble, updateEnsemble, deleteEnsemble,
  listEnsembleInstruments, createEnsembleInstruments, updateEnsembleInstruments, deleteEnsembleInstruments,
  listFestivals, createFestival, updateFestival, deleteFestival,
  listForms, createForm, updateForm, deleteForm,
  listInstruments, createInstrument, updateInstrument, deleteInstrument,
  listInvoices, createInvoice, updateInvoice, deleteInvoice,
  /*listPurchaseOrders, createPurchaseOrder, updatePurchaseOrder, deletePurchaseOrder,*/
  listPayments, createPayment, updatePayment, deletePayment,
  listSchools, createSchool, updateSchool, deleteSchool,
  listSelections, createSelection, updateSelection, deleteSelection,
  listSettings, createSetting, updateSetting, deleteSetting,
  listStudents, createStudent, updateStudent, deleteStudent,
  listTickets, createTicket, updateTicket, deleteTicket,
  listTicketReplies, createTicketReply, updateTicketReply, deleteTicketReply,
  listUsers, createUser, updateUser, deleteUser,
  listUserSchools, createUserSchools, updateUserSchools, deleteUserSchools,
  listZones, createZone, updateZone, deleteZone,
} from './queries/import';

import {
	adminListGroups,
	adminListUsers,
	adminListUsersInGroup,
	adminCreateUser,
	adminConfirmSignUp,
	adminAddUserToGroup,
	adminDeleteUser,
} from '@/scripts/aws'

import ImportCard from '@/views/dev/import/ImportCard.vue';
import ImportCardCognito from '@/views/dev/import/ImportCardCognito.vue';
import importMixin from '@/views/dev/import/import.mixin';

export default {
  name: 'Import',
  components: { ImportCard, ImportCardCognito },
	mixins: [ importMixin ],
  data() {
    return {
			search: null,
			items: [
					{
							name: 'Applications',
							key: 'id',
							queryList: listApplications,
							mutationCreate: createApplication,
							mutationUpdate: updateApplication,
							mutationDelete: deleteApplication,
							meta: {
									tags: ['application'],
									dependencies: ['festival', 'form', 'instrument', 'student', 'user']
							},
					},
        {
          name: 'Buses',
          key: 'id',
          queryList: listBuses,
          mutationCreate: createBus,
          mutationUpdate: updateBus,
          mutationDelete: deleteBus,
          meta: {
            tags: ['bus'],
            dependencies: ['student']
          },
        },
					{
							name: 'Districts',
							key: 'id',
							queryList: listDistricts,
							mutationCreate: createDistrict,
							mutationUpdate: updateDistrict,
							mutationDelete: deleteDistrict,
							meta: {
									tags: ['district'],
									dependencies: ['zone']
							},
					},
					{
							name: 'Documents',
							key: 'id',
							queryList: listDocuments,
							mutationCreate: createDocument,
							mutationUpdate: updateDocument,
							mutationDelete: deleteDocument,
							meta: {
									tags: ['document'],
									dependencies: [],
									variant: 'success',
							},
					},
					{
							name: 'Document Categories',
							key: 'id',
							queryList: listDocumentCategories,
							mutationCreate: createDocumentCategory,
							mutationUpdate: updateDocumentCategory,
							mutationDelete: deleteDocumentCategory,
							meta: {
									tags: ['document', 'documentCategory'],
									dependencies: ['document'],
							},
					},

					{
							name: 'Ensembles',
							key: 'id',
							queryList: listEnsembles,
							mutationCreate: createEnsemble,
							mutationUpdate: updateEnsemble,
							mutationDelete: deleteEnsemble,
							meta: {
									tags: ['ensemble'],
									dependencies: []
							},
					},
					{
							name: 'Ensemble Instruments',
							key: 'id',
							queryList: listEnsembleInstruments,
							mutationCreate: createEnsembleInstruments,
							mutationUpdate: updateEnsembleInstruments,
							mutationDelete: deleteEnsembleInstruments,
							meta: {
									tags: ['ensemble', 'ensembleInstrument'],
									dependencies: ['ensemble', 'instrument']
							},
					},
					{
							name: 'Festivals',
							key: 'id',
							queryList: listFestivals,
							mutationCreate: createFestival,
							mutationUpdate: updateFestival,
							mutationDelete: deleteFestival,
							meta: {
									tags: ['festival'],
									dependencies: ['zone']
							},
					},
					{
							name: 'Forms',
							key: 'id',
							queryList: listForms,
							mutationCreate: createForm,
							mutationUpdate: updateForm,
							mutationDelete: deleteForm,
							meta: {
									tags: ['form'],
									dependencies: []
							},
					},
					{
							name: 'Instruments',
							key: 'id',
							queryList: listInstruments,
							mutationCreate: createInstrument,
							mutationUpdate: updateInstrument,
							mutationDelete: deleteInstrument,
							meta: {
									tags: ['instrument'],
									dependencies: ['form']
							},
					},
					{
							name: 'Invoices',
							key: 'id',
							queryList: listInvoices,
							mutationCreate: createInvoice,
							mutationUpdate: updateInvoice,
							mutationDelete: deleteInvoice,
							meta: {
									tags: ['invoice'],
									dependencies: ['student']
							},
					},
				/*	{
							name: 'Purchase Orders',
							key: 'id',
							queryList: listPurchaseOrders,
							mutationCreate: createPurchaseOrder,
							mutationUpdate: updatePurchaseOrder,
							mutationDelete: deletePurchaseOrder,
							meta: {
									tags: ['purchaseOrder'],
									dependencies: ['school']
							},
					},*/
					{
							name: 'Payments',
							key: 'id',
							queryList: listPayments,
							mutationCreate: createPayment,
							mutationUpdate: updatePayment,
							mutationDelete: deletePayment,
							meta: {
									tags: ['payment'],
									dependencies: ['invoice', 'purchaseOrder']
							},
					},
					{
							name: 'Schools',
							key: 'id',
							keysToDelete: ['districtSchoolsId'],
							queryList: listSchools,
							mutationCreate: createSchool,
							mutationUpdate: updateSchool,
							mutationDelete: deleteSchool,
							meta: {
									tags: ['school'],
									dependencies: ['district', 'zone']
							},
					},
					{
							name: 'Selections',
							key: 'id',
							queryList: listSelections,
							mutationCreate: createSelection,
							mutationUpdate: updateSelection,
							mutationDelete: deleteSelection,
							meta: {
									tags: ['selection'],
									dependencies: ['application', 'ensemble']
							},
					},
					{
							name: 'Settings',
							key: 'key',
							queryList: listSettings,
							mutationCreate: createSetting,
							mutationUpdate: updateSetting,
							mutationDelete: deleteSetting,
							meta: {
									tags: ['setting'],
									dependencies: []
							},
					},
					{
							name: 'Students',
							key: 'id',
							queryList: listStudents,
							mutationCreate: createStudent,
							mutationUpdate: updateStudent,
							mutationDelete: deleteStudent,
							meta: {
									tags: ['student'],
									dependencies: ['school', 'invoice']
							},
					},
					{
							name: 'Tickets',
							key: 'id',
							queryList: listTickets,
							mutationCreate: createTicket,
							mutationUpdate: updateTicket,
							mutationDelete: deleteTicket,
							meta: {
									tags: ['ticket'],
									dependencies: ['user']
							},
					},
					{
							name: 'Ticket Replies',
							key: 'id',
							queryList: listTicketReplies,
							mutationCreate: createTicketReply,
							mutationUpdate: updateTicketReply,
							mutationDelete: deleteTicketReply,
							meta: {
									tags: ['ticket', 'ticketReply'],
									dependencies: ['ticket', 'user']
							},
					},
					{
							name: 'Users',
							key: 'id',
							keysToDelete: ['districtUsersId'],
							queryList: listUsers,
							mutationCreate: createUser,
							mutationUpdate: updateUser,
							mutationDelete: deleteUser,
							meta: {
									tags: ['user'],
									dependencies: []
							},
					},
					{
							name: 'User Schools',
							key: 'id',
							queryList: listUserSchools,
							mutationCreate: createUserSchools,
							mutationUpdate: updateUserSchools,
							mutationDelete: deleteUserSchools,
							meta: {
									tags: ['user', 'userSchool'],
									dependencies: ['user', 'school']
							},
					},
					{
							name: 'Zones',
							key: 'id',
							queryList: listZones,
							mutationCreate: createZone,
							mutationUpdate: updateZone,
							mutationDelete: deleteZone,
							meta: {
									tags: ['zone'],
									dependencies: []
							},
					},
			],
			cognito: [
				{
					name: 'Cognito User',
					key: 'Username',
					queryList: adminListUsers,
					queryOptions: { },
					mutationCreate: adminCreateUser,
					mutationCreateOptions: { suppressEmail: true, email_verified: true, username: null, email: null },
					mutationDelete: adminDeleteUser,
					meta: {
						tags: ['application'],
						dependencies: ['festival', 'form', 'instrument', 'student', 'user']
					},
				},
			],
			cognitoImportData: [
				{
					Attributes: [
						{
							Name: 'sub',
							Value: '7d3a3ab2-d567-42ca-ab66-b0778066dd52'
						},
						{
							Name: 'email_verified',
							Value: 'true'
						},
						{
							Name: 'phone_number',
							Value: '+15188103880'
						},
						{
							Name: 'email',
							Value: 'daniel.whitehouse@neric.org'
						}
					],
					Enabled: true,
					UserCreateDate: '2023-03-10T15:01:57.372Z',
					UserLastModifiedDate: '2023-03-15T15:16:14.574Z',
					UserStatus: 'CONFIRMED',
					Username: 'cognito_import_1'
				},
				{
					Attributes: [
						{
							Name: 'sub',
							Value: 'df7bbad3-2dde-42be-b2b7-d08068b2a595'
						},
						{
							Name: 'custom:user_id',
							Value: 'df7bbad3-2dde-42be-b2b7-d08068b2a777'
						},
						{
							Name: 'phone_number',
							Value: '+15188103880'
						},
						{
							Name: 'email',
							Value: 'danwhitehouse@gmail.com'
						}
					],
					Enabled: true,
					UserCreateDate: '2023-02-13T13:56:20.365Z',
					UserLastModifiedDate: '2023-03-10T03:16:05.031Z',
					UserStatus: 'CONFIRMED',
					Username: 'cognito_import_2'
				},
			]
		}
  },
	computed: {
    filteredItems() {
        if (!this.search) {
            return this.items;
        }
        return this.items.filter(item => item.name.toLowerCase().includes(this.search.toLowerCase()));
    },
    dependentItems() {
        const dependencies = new Set(
            this.filteredItems.flatMap(item => item.meta.dependencies.map(dependency => dependency.toLowerCase()))
        );
        return this.items.filter(item => dependencies.has(item.name.toLowerCase()) && !this.filteredItems.some(filteredItem => filteredItem.name === item.name));
    },
},
	methods: {
		async testCognito() {
			const users = await adminListUsers();

			await Promise.all(this.cognitoImportData.map(async (user, index) => {
				const options = {
					username: user.Username,
					suppressEmail: true,
					email_verified: true,
					temporary_password: 'p@ssWord123',
				};
				user.Attributes.forEach((attribute) => {
					if (attribute.Name === 'sub' && !options.user_id) {
						options.user_id = attribute.Value;
					}
					else if (attribute.Name === 'custom:user_id') {
						options.user_id = attribute.Value;
					}
					else if (attribute.Name === 'email') {
						options.email = attribute.Value;
					}
					else if (attribute.Name === 'phone_number') {
						options.phone_number = attribute.Value;
					}
				});

				try {
					const adminCreateUserResponse = await adminCreateUser(options);
				}
				catch (error) {
					console.error(`Error creating user ${user.Username}:`, error.message);
				}
			}));
		}
	}
}
</script>
