export default {
    filters: {
        duration(milliseconds, percentage) {
            const remainingMilliseconds = milliseconds * (1 - percentage);
            const seconds = remainingMilliseconds / 1000;
            const minutes = seconds / 60;
            const hours = minutes / 60;

            if (hours >= 1) {
                const remainingMinutes = Math.floor(minutes % 60);
                return `${Math.floor(hours)} hours ${remainingMinutes} minutes`;
            }
            if (minutes >= 1) {
                return `${Math.floor(minutes)} minutes`;
            }
            return `${Math.floor(seconds)} seconds`;
        },
        clamp(number) {
            return parseFloat(number.toFixed(2));
        }
    },
    methods: {
        /** Export Errors **/
        async copyErrorsToClipboard(items) {
            const errorMessages = items.map(error => error.message).join('\n');
            try {
                await navigator.clipboard.writeText(errorMessages);
                this.$bvToast.toast('Errors copied to clipboard.', {
                    title: 'Copied!',
                    variant: 'success',
                    autoHideDelay: 1000,
                    appendToast: true,
                    solid: true,
                    toaster: 'b-toaster-bottom-right',
                });
            } catch (err) {
                console.error('Failed to copy errors to clipboard:', err);
                this.$bvToast.toast('Failed to copy errors to clipboard.', {
                    title: 'Error',
                    variant: 'danger',
                    autoHideDelay: 1000,
                    appendToast: true,
                    solid: true,
                    toaster: 'b-toaster-bottom-right',
                });
            }
        },
        downloadErrorsAsJson(items, tableName) {
            const name = tableName.toLowerCase().replace(/\s+/g, '-')
            const errorMessages = items.map(error => error.message);
            const dataStr = JSON.stringify(errorMessages);
            const blob = new Blob([dataStr], { type: 'application/json' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `nyssma-import-${name}-errors-${new Date().toISOString().replace(/:/g, '_')}.json`;
            link.click();
            URL.revokeObjectURL(url);
        },
    }
}
