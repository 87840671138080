<template>
	<b-card bg-variant="light-primary">
		<!-- Header -->
		<div class="d-flex justify-content-between mb-2">
			<b-card-title class="mb-0">
				{{ title }}
			</b-card-title>
			<div id="checkbox-group">
				<b-checkbox v-model="deletion.enabled"
										v-b-tooltip="{
											placement: 'top',
											variant: 'white',
											trigger: 'hover',
											delay: { show: 1000 },
											html: true,
											title: `When <strong>enabled</strong>, this will delete all records in the table before inserting any new records.`
										}"
										aria-label="Update"
										switch inline size="sm"
										:disabled="query.processing || deletion.processing || upsert.processing">
					Delete All
				</b-checkbox>
				<b-checkbox v-model="upsert.insert.enabled"
										v-b-tooltip="{
											placement: 'top',
											variant: 'white',
											trigger: 'hover',
											delay: { show: 1000 },
											html: true,
											title: 'When <strong>enabled</strong>, new records will be created. When <strong>disabled</strong>, new records will be skipped.'
										}"
										switch inline size="sm"
										:disabled="query.processing || deletion.processing || upsert.processing">

					Insert
				</b-checkbox>
				<b-checkbox v-model="upsert.update.enabled"
										v-b-tooltip="{
											placement: 'top',
											variant: 'white',
											trigger: 'hover',
											delay: { show: 1000 },
											html: true,
											title: `When <strong>enabled</strong>, existing records will be updated. When <strong>disabled</strong>, existing records will be skipped.`
										}"
										aria-label="Update"
										switch inline size="sm"
										:disabled="query.processing || deletion.processing || upsert.processing">
					Update
				</b-checkbox>
			</div>
		</div>
		<!-- Import Form -->
		<b-row>
			<b-col class="pr-0">
				<b-form-file v-model="cognitoUsersFile"
										 :disabled="query.processing || deletion.processing || upsert.processing"
										 accept=".json"
										 placeholder="Cognito Users"
										 class="mb-50"
										 @input="clear"/>
				<b-form-file v-model="cognitoGroupUsersFile"
										 :disabled="query.processing || deletion.processing || upsert.processing"
										 accept=".json"
										 placeholder="Cognito Group Users"
										 class="mb-50"
										 @input="clear"/>

				<hr/>

				<b-row>
					<b-col>
						<b-form-group label="Suppress Email" label-for="suppress-email" >
							<b-checkbox id="suppress-email" v-model="suppressEmail">
								<small>
									If checked, the user will not receive a welcome email.
									This requires the user to have temporary password.
									Additionally, this will set the importing email address as verified.
								</small>
							</b-checkbox>
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group label="Temporary Password" label-for="temp-password" >
							<b-form-input id="temp-password"
														v-model="temporaryPassword"
														:disabled="!suppressEmail || query.processing || deletion.processing || upsert.processing"
														placeholder="Temporary Password"
							/>
						</b-form-group>
					</b-col>
				</b-row>




			</b-col>
			<b-col cols="auto">
				<b-button variant="primary" :disabled="!cognitoUsersFile || !cognitoGroupUsersFile || query.processing || deletion.processing || upsert.processing" @click="processFile">Import</b-button>
			</b-col>
		</b-row>

		<!-- Status && Progress Bars -->
		<div class="mt-2">
			<!-- Status -->
			<div class="d-flex justify-content-between font-small-2 pb-25">
				{{ progressStatusText }}
				<div v-if="query.processing">
					<span class="percentage">{{ (query.processed / query.total) * 100 | clamp }}%</span>
				</div>
				<div v-if="deletion.processing">
					<span>(About {{ deletion.expectedDuration | duration(deletion.processed / deletion.total) }})</span>
					<span class="percentage">{{ (deletion.processed / deletion.total) * 100 | clamp }}%</span>
				</div>
				<div v-if="upsert.processing">
					<span>(About {{ upsert.expectedDuration | duration(upsert.processed / upsert.total) }})</span>
					<span class="percentage">{{ (upsert.processed / upsert.total) * 100 | clamp }}%</span>
				</div>
				<div v-if="query.completed && upsert.completed">
					<span>Deleted: {{ deletion.processed }}</span> |
					<span>Created: {{ upsert.insert.success }}/{{ upsert.insert.processed }}</span> |
					<span>Updated: {{ upsert.update.success }}/{{ upsert.update.processed }}</span> |
					<span>Total: {{ upsert.total }}</span>
				</div>
			</div>

			<!-- Progress -->
			<b-progress :max="100">
				<b-progress-bar :value="queryProgressBarPercentage" :variant="queryProgressBarVariant" />
				<b-progress-bar v-if="deletion.enabled" :value="deleteProgressBarPercentage" :variant="deleteProgressBarVariant" />
				<b-progress-bar :value="upsertProgressBarPercentage" :variant="upsertProgressBarVariant" />
			</b-progress>
		</div>

		<!-- Errors -->
		<div v-if="errors.items.length > 0" class="mt-1 mb-0 border rounded">
			<div class="d-flex justify-content-between align-items-center p-1">
				<div class="font-weight-bold">
					<span>Errors ({{ errors.items.length }})</span>
				</div>

				<div>
					<b-button variant="link" size="sm" title="Copy Errors" @click="copyErrorsToClipboard(errors.items)">
						<font-awesome-icon :icon="['far', 'copy']" />
					</b-button>
					<b-button variant="link" size="sm" title="Download Errors" @click="downloadErrorsAsJson(errors.items, title)">
						<font-awesome-icon :icon="['fas', 'cloud-arrow-down']" />
					</b-button>
					<b-button variant="link" size="sm"
										aria-controls="collapse-errors"
										:aria-expanded="errors.visible ? 'true' : 'false'"
										:class="[errors.visible ? null : 'collapsed', 'btn-icon']"
										@click="errors.visible = !errors.visible">

						<font-awesome-icon :icon="['fas', errors.visible ? 'chevron-up' : 'chevron-down']" />
					</b-button>
				</div>

			</div>
			<b-collapse id="collapse-errors" v-model="errors.visible">
				<vue-perfect-scrollbar :settings="{ maxScrollbarLength: 150,wheelPropagation: false }" class="ps-import-errors">
					<b-list-group flush>
						<b-list-group-item v-for="(error, index) in errors.items" :key="index">{{ error.message }}</b-list-group-item>
					</b-list-group>
				</vue-perfect-scrollbar>
			</b-collapse>
		</div>
	</b-card>
</template>

<script>
/* eslint-disable no-await-in-loop */

import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import importMixin from '@/views/dev/import/import.mixin';
import {
  adminAddUserToGroup,
  adminCreateUser,
  adminDeleteUser,
  adminListGroups,
  adminListUsers,
  adminListUsersInGroup,
  adminRemoveUserFromGroup,
  adminUpdateUserAttributes
} from '@/scripts/aws';

export default {
	name: 'ImportCardCognitoUsers',
	components: { VuePerfectScrollbar },
	mixins: [ importMixin ],
	data() {
			return {
				title: 'Users: Cognito',
				cognitoUsersFile: null,
				cognitoGroupUsersFile: null,
				suppressEmail: true,
				temporaryPassword: '6humg@7Y',


				query: {
					total: 0,
					processed: 0,
					processing: false,
					completed: false,
					expectedDuration: 0,
				},
				deletion: {
					enabled: false,
					total: 0,
					processed: 0,
					processing: false,
					completed: false,
					expectedDuration: 0,
				},
				upsert: {
					total: 0,
					processed: 0,
					processing: false,
					completed: false,
					expectedDuration: 0,
					insert: {
						enabled: true,
						processed: 0,
						success: 0,
						skipped: 0,
					},
					update: {
						enabled: true,
						processed: 0,
						success: 0,
						skipped: 0,
					},
				},
				errors: {
					visible: false,
					items: []
				},
			};
	},
	computed: {
		progressStatusText() {
			if (this.query.completed && this.upsert.completed) { return 'Completed' }
			if (this.query.processing) { return 'Querying records' }
			if (this.deletion.processing) { return 'Deleting records' }
			if (this.upsert.processing) { return 'Inserting/Updating records' }
			return ''
		},
		queryProgressBarVariant() {
			const queryPercentage = this.queryProgressBarPercentage;
			if (queryPercentage > 0 && queryPercentage < 25) {
				return 'primary';
			}
			if (queryPercentage === 25) {
				return 'success';
			}
			return 'dark';
		},
		queryProgressBarPercentage() {
			if (this.query.total === 0) { return 0 } // stops the value from being NaN
			return (this.query.processed / this.query.total) * 25;
		},
		deleteProgressBarVariant() {
			const deletePercentage = this.deleteProgressBarPercentage;
			if (deletePercentage > 0 && deletePercentage < 25) {
				return 'primary';
			}
			if (deletePercentage === 25) {
				return 'success';
			}
			return 'dark';
		},
		deleteProgressBarPercentage() {
			if (this.deletion.total === 0) { return 0 } // stops the value from being NaN
			return (this.deletion.processed / this.deletion.total) * 25;
		},
		upsertProgressBarVariant() {
			const mutationPercentage = this.upsertProgressBarPercentage;
			const maxPercentage = this.deletion.enabled ? 50 : 75;
			if (mutationPercentage > 0 && mutationPercentage < maxPercentage) {
				return 'primary';
			}
			if (this.upsert.completed && this.errors.items.length === 0) {
				return 'success';
			}
			if (this.upsert.completed && this.errors.items.length > 0 && this.errors.items.length < this.upsert.total) {
				return 'warning';
			}
			if (this.upsert.completed && this.errors.items.length === this.upsert.total) {
				return 'danger';
			}
			return 'dark';
		},
		upsertProgressBarPercentage() {
			const maxPercentage = this.deletion.enabled ? 50 : 75;
			if (this.upsert.total === 0) { return 0; } // stops the value from being NaN
			return (this.upsert.processed / this.upsert.total) * maxPercentage;
		},
	},
	methods: {
		clear() {
			this.query = {
				total: 0,
				processed: 0,
				processing: false,
				completed: false
			};

			this.deletion = {
				enabled: this.deletion.enabled,
				total: 0,
				processed: 0,
				processing: false,
				completed: false,
				expectedDuration: 0,
			};

			this.upsert = {
				total: 0,
				processed: 0,
				processing: false,
				completed: false,
				expectedDuration: 0,
				insert: {
					enabled: this.upsert.insert.enabled,
					processed: 0,
					success: 0,
					skipped: 0,
				},
				update: {
					enabled: this.upsert.update.enabled,
					processed: 0,
					success: 0,
					skipped: 0,
				}
			};
		},

		async processFile() {
			this.clear()
			if (!this.cognitoUsersFile) return;

			const fileReader = new FileReader();
			fileReader.onload = async (event) => {
				// Query existing records
				const existingItems = await this.listItems();
				const existingItemsMap = new Map(existingItems.map(item => [item.Username, item]));
				const batchSize = 20; // number of records to process in a batch
				const delayBetweenBatches = 1000; // delay between batches (in milliseconds)

				// Delete records
				if(this.deletion.enabled) {
					this.deletion.processing = true;
					this.deletion.total = existingItems.length
					this.deletion.expectedDuration = Math.ceil(existingItems.length / batchSize) * delayBetweenBatches;

					for (let i = 0; i < existingItems.length; i += batchSize) {
						const batch = existingItems.slice(i, i + batchSize);
						await Promise.all(batch.map(async (item, index) => {
							if(item.Username !== 'danwhitehouse') {
								await this.deleteItem(item);
								existingItemsMap.delete(item.Username);
								this.deletion.processed += 1
							}
						}));

						if (i + batchSize < existingItems.length) {
							await new Promise((resolve) => setTimeout(resolve, delayBetweenBatches))
						}
					}
					this.deletion.processing = false;
					this.deletion.completed = true;
				}

				// Process file
				const fileItems = JSON.parse(event.target.result);
				this.upsert.processing = true;
				this.upsert.total = fileItems.length;
				this.upsert.expectedDuration = Math.ceil(fileItems.length / batchSize) * delayBetweenBatches;

				// Create or update records
				if(this.upsert.insert.enabled || this.upsert.update.enabled) {
					const fileGroupUsersMap = await this.generateFileGroupUsersMap(fileItems);
					const existingGroupUsersMap = await this.getExistingGroupUsers();

					for (let i = 0; i < fileItems.length; i += batchSize) {
						const batch = fileItems.slice(i, i + batchSize);
						await Promise.all(batch.map(async (fileItem, index) => {
							const fileItemGroups = fileGroupUsersMap.get(fileItem.Username) || []; //Get the groups for the file item being processed

							if (this.upsert.update.enabled && existingItemsMap.has(fileItem.Username)) {
								// Run update mutation
								const existingItem = existingItemsMap.get(fileItem.Username); //Get the existing item from the map
								const existingItemGroups = existingGroupUsersMap.get(fileItem.Username) || []; //Get the groups for the existing item

								await this.updateItem(fileItem, fileItemGroups, existingItem, existingItemGroups);
								this.upsert.update.processed += 1
							}
							else if(this.upsert.insert.enabled) {
								// Run create mutation
								await this.createItem(fileItem, fileItemGroups);
								this.upsert.insert.processed += 1
							}
							this.upsert.processed += 1;
						}));

						if (i + batchSize < fileItems.length) {
							await new Promise((resolve) => setTimeout(resolve, delayBetweenBatches))
						}
					}
				}
				this.upsert.processing	= false;
				this.upsert.completed = true;
			};
			fileReader.readAsText(this.cognitoUsersFile);
		},

		async getExistingGroupUsers() {
			try {
				const cognitoGroupsResponse = await adminListGroups();
				const groups = cognitoGroupsResponse.Groups;

				const groupMembersPromises = groups.map(async (group) => {
					const groupUsers = await adminListUsersInGroup(group.GroupName);
					return groupUsers.map((user) => ({ groupName: group.GroupName, user: user.Username }));
				});

				const groupMembersArray = await Promise.all(groupMembersPromises);
				const flattenedGroupMembers = groupMembersArray.flat();

				const groupMembersMap = new Map();

				flattenedGroupMembers.forEach((groupMember) => {
					const { groupName, user } = groupMember;
					const existingGroups = groupMembersMap.get(user) || [];
					existingGroups.push(groupName);
					groupMembersMap.set(user, existingGroups);
				});
				return groupMembersMap;
			}
			catch (e) {
				console.error(e);
				return new Map();
			}
		},

		async generateFileGroupUsersMap() {
			const readFileContent = (file) => new Promise((resolve, reject) => {
					const groupMembersMap = new Map();
					const fileReader = new FileReader();
					fileReader.onload = (event) => {
						try {
							const fileItems = JSON.parse(event.target.result);
							fileItems.forEach((item) => {
								const users = item.users || [];
								users.forEach((user) => {
									const groups = groupMembersMap.get(user.Username) || [];
									groups.push(item.name);
									groupMembersMap.set(user.Username, groups);
								});
							});
							resolve(groupMembersMap);
						}
						catch (error) {
							reject(error);
						}
					};
					fileReader.onerror = (error) => {
						reject(error);
					};
					fileReader.readAsText(file);
				});

			try {
        return await readFileContent(this.cognitoGroupUsersFile);
			}
      catch (error) {
				console.error('Error processing the groups file:', error);
				return new Map();
			}
		},

		async listItems() {
			this.query.processing = true;
			const items = await adminListUsers()
			this.query.total += 1;
			this.query.processing = false;
			this.query.completed = true;
			return items;
		},

		async createItem(fileItem, fileItemGroups) {
			const options = {
				username: fileItem.Username,
				/*suppressEmail: true,
				email_verified: true,
				temporary_password: this.temporaryPassword*/
			};

			if(this.suppressEmail === true) {
				options.suppressEmail = true;
				options.email_verified = true;
				options.temporary_password = this.temporaryPassword;
			}

			fileItem.Attributes.forEach((attribute) => {
				if (attribute.Name === 'sub' && !options.user_id) {
					options.user_id = attribute.Value;
				}
				else if (attribute.Name === 'custom:user_id') {
					options.user_id = attribute.Value;
				}
				else if (attribute.Name === 'email') {
					options.email = attribute.Value;
				}
				else if (attribute.Name === 'phone_number') {
					options.phone_number = attribute.Value;
				}
			});

			try {
				await adminCreateUser(options)
				this.upsert.insert.success += 1;

				if (fileItemGroups.length > 0) {
					await Promise.all(
							fileItemGroups.map(async group => {
								await adminAddUserToGroup(fileItem.Username, group);
							})
					);
				}
			}
			catch (error) {
				console.error('Error inserting item:', error);
				this.errors.items.push(`Error inserting record ${JSON.stringify(fileItem)}: ${error}`);
			}
		},

		async updateItem(fileItem, fileItemGroups, existingItem, existingItemGroups) {
			try {
				const itemAttributes = new Map(fileItem.Attributes.map(attribute => [attribute.Name, attribute.Value]));
				const existingItemAttributes = new Map(existingItem.Attributes.map(attribute => [attribute.Name, attribute.Value]));
				const attributes = []
				itemAttributes.forEach((value, key) => {
					if (key !== 'sub' && (!existingItemAttributes.has(key) || existingItemAttributes.get(key) !== value)) {
						attributes.push({ Name: key, Value: value });

						// Check if email or phone_number attributes have changed and add verified attributes accordingly
						if (key === 'email' && existingItemAttributes.get(key) !== value) {
							attributes.push({ Name: 'email_verified', Value: 'true' });
						}
						else if (key === 'phone_number' && existingItemAttributes.get(key) !== value) {
							attributes.push({ Name: 'phone_number_verified', Value: 'true' });
						}
					}
				});

				if (attributes.length > 0) {
					await adminUpdateUserAttributes(fileItem.Username, attributes)
					this.upsert.update.success += 1;
				}
				else {
					this.upsert.update.skipped += 1;
				}

				// Find groups to add and remove
				const groupsToAdd = fileItemGroups.filter((g) => !existingItemGroups.includes(g));
				const groupsToRemove = existingItemGroups.filter((g) => !fileItemGroups.includes(g));

				if(groupsToAdd.length > 0) {
					await Promise.all(
							groupsToAdd.map(async group => {
								await adminAddUserToGroup(fileItem.Username, group);
							})
					);
				}
				if(groupsToRemove.length > 0) {
					await Promise.all(
							groupsToRemove.map(async group => {
								await adminRemoveUserFromGroup(fileItem.Username, group);
							})
					);
				}
			}
			catch (error) {
				console.error('Error updating record:', error);
				this.errors.items.push(`Error updating item ${JSON.stringify(fileItem)}: ${error}`);
			}
		},

		async deleteItem(item) {
			try {
				await adminDeleteUser(item.Username)
			}
			catch (error) {
				console.error('Error deleting record:', error);
				this.errors.items.push(`Error deleting item ${JSON.stringify(item)}: ${error}`);
			}
		},
	}
};
</script>

<style>
	.ps-import-errors {
		max-height: 300px;
	}
	.percentage {
		width: 6ch;
		display: inline-flex;
		justify-content: end;
	}
</style>
