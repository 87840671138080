<template>
	<b-card>
		<!-- Header -->
		<div class="d-flex justify-content-between mb-2">
			<b-card-title class="mb-0">
				{{ title }}
			</b-card-title>
			<div id="checkbox-group">
				<b-checkbox v-model="deletion.enabled"
										v-b-tooltip="{
											placement: 'top',
											variant: 'white',
											trigger: 'hover',
											delay: { show: 1000 },
											html: true,
											title: `When <strong>enabled</strong>, this will delete all records in the table before inserting any new records.`
										}"
										aria-label="Update"
										switch inline size="sm"
										:disabled="query.processing || deletion.processing || upsert.processing">
					Delete All
				</b-checkbox>
				<b-checkbox v-model="upsert.insert.enabled"
										v-b-tooltip="{
											placement: 'top',
											variant: 'white',
											trigger: 'hover',
											delay: { show: 1000 },
											html: true,
											title: 'When <strong>enabled</strong>, new records will be created. When <strong>disabled</strong>, new records will be skipped.'
										}"
										switch inline size="sm"
										:disabled="query.processing || deletion.processing || upsert.processing">

					Insert
				</b-checkbox>
				<b-checkbox v-model="upsert.update.enabled"
										v-b-tooltip="{
											placement: 'top',
											variant: 'white',
											trigger: 'hover',
											delay: { show: 1000 },
											html: true,
											title: `When <strong>enabled</strong>, existing records will be updated. When <strong>disabled</strong>, existing records will be skipped.`
										}"
										aria-label="Update"
										switch inline size="sm"
										:disabled="query.processing || deletion.processing || upsert.processing">
					Update
				</b-checkbox>
			</div>
		</div>
		<!-- Import Form -->
		<b-row>
			<b-col class="pr-0">
				<b-form-file v-model="selectedFile" :disabled="query.processing || deletion.processing || upsert.processing" accept=".json" @input="clear"/>
			</b-col>
			<b-col cols="auto">
				<b-button variant="primary" :disabled="!selectedFile || query.processing || deletion.processing || upsert.processing" @click="processFile">Import</b-button>
			</b-col>
		</b-row>

		<!-- Status && Progress Bars -->
		<div class="mt-2">
			<!-- Status -->
			<div class="d-flex justify-content-between font-small-2 pb-25">
				{{ progressStatusText }}
				<div v-if="query.processing">
					<span class="percentage">{{ (query.processed / query.total) * 100 | clamp }}%</span>
				</div>
				<div v-if="deletion.processing">
					<span>(About {{ deletion.expectedDuration | duration(deletion.processed / deletion.total) }})</span>
					<span class="percentage">{{ (deletion.processed / deletion.total) * 100 | clamp }}%</span>
				</div>
				<div v-if="upsert.processing">
					<span>(About {{ upsert.expectedDuration | duration(upsert.processed / upsert.total) }})</span>
					<span class="percentage">{{ (upsert.processed / upsert.total) * 100 | clamp }}%</span>
				</div>
				<div v-if="query.completed && upsert.completed">
					<span>Deleted: {{ deletion.processed }}</span> |
					<span>Created: {{ upsert.insert.success }}/{{ upsert.insert.processed }}</span> |
					<span>Updated: {{ upsert.update.success }}/{{ upsert.update.processed }}</span> |
					<span>Total: {{ upsert.total }}</span>
				</div>
			</div>

			<!-- Progress -->
			<b-progress :max="100">
				<b-progress-bar :value="queryProgressBarPercentage" :variant="queryProgressBarVariant" />
				<b-progress-bar v-if="deletion.enabled" :value="deleteProgressBarPercentage" :variant="deleteProgressBarVariant" />
				<b-progress-bar :value="upsertProgressBarPercentage" :variant="upsertProgressBarVariant" />
			</b-progress>
		</div>

		<!-- Errors -->
		<div v-if="errors.items.length > 0" class="mt-1 mb-0 border rounded">
			<div class="d-flex justify-content-between align-items-center p-1">
				<div class="font-weight-bold">
					<span>Errors ({{ errors.items.length }})</span>
				</div>

				<div>
					<b-button variant="link" size="sm" title="Copy Errors" @click="copyErrorsToClipboard(errors.items)">
						<font-awesome-icon :icon="['far', 'copy']" />
					</b-button>
					<b-button variant="link" size="sm" title="Download Errors" @click="downloadErrorsAsJson(errors.items, title)">
						<font-awesome-icon :icon="['fas', 'cloud-arrow-down']" />
					</b-button>
					<b-button variant="link" size="sm"
										aria-controls="collapse-errors"
										:aria-expanded="errors.visible ? 'true' : 'false'"
										:class="[errors.visible ? null : 'collapsed', 'btn-icon']"
										@click="errors.visible = !errors.visible">

						<font-awesome-icon :icon="['fas', errors.visible ? 'chevron-up' : 'chevron-down']" />
					</b-button>
				</div>

			</div>
			<b-collapse id="collapse-errors" v-model="errors.visible">
				<vue-perfect-scrollbar :settings="{ maxScrollbarLength: 150,wheelPropagation: false }" class="ps-import-errors">
					<b-list-group flush>
						<b-list-group-item v-for="(error, index) in errors.items" :key="index">{{ error.message }}</b-list-group-item>
					</b-list-group>
				</vue-perfect-scrollbar>
			</b-collapse>
		</div>
	</b-card>
</template>

<script>
/* eslint-disable no-await-in-loop */

import {API} from 'aws-amplify';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import importMixin from '@/views/dev/import/import.mixin';

export default {
	name: 'FileImport',
	components: { VuePerfectScrollbar },
	mixins: [ importMixin ],
	props: {
		title: {
			type: String,
			required: true,
			description: 'Title of the card.'
		},
		queryList: {
			type: String,
			required: true,
			description: 'Query used to list existing items.'
		},
		mutationCreate: {
			type: String,
			required: true,
			description: 'Mutation used to create a new item.'
		},
		mutationUpdate: {
			type: String,
			required: true,
			description: 'Mutation used to update an existing items.'
		},
		mutationDelete: {
			type: String,
			required: true,
			description: 'Mutation used to delete an existing items.'
		},
		itemKey: {
			type: String,
			default: 'id',
			description: 'Key used to identify an existing items.'
		},
		itemKeysToDelete: {
			type: Array,
			default: () => [],
			description: 'Remove the fields while inserting and updating from the item before sending to the API.'
		},
		state: {
			type: Object,
			default: () => ({
				create: true,
				update: true
			}),
		}
	},
	data() {
			return {
				selectedFile: null,
				query: {
					total: 0,
					processed: 0,
					processing: false,
					completed: false,
					expectedDuration: 0,
				},
				deletion: {
					enabled: false,
					total: 0,
					processed: 0,
					processing: false,
					completed: false,
					expectedDuration: 0,
				},
				upsert: {
					total: 0,
					processed: 0,
					processing: false,
					completed: false,
					expectedDuration: 0,
					insert: {
						enabled: true,
						processed: 0,
						success: 0,
					},
					update: {
						enabled: true,
						processed: 0,
						success: 0,
					},
				},
				errors: {
					visible: false,
					items: []
				},
			};
	},
	computed: {
		progressStatusText() {
			if (this.query.completed && this.upsert.completed) { return 'Completed' }
			if (this.query.processing) { return 'Querying records' }
			if (this.deletion.processing) { return 'Deleting records' }
			if (this.upsert.processing) { return 'Inserting/Updating records' }
			return ''
		},
		queryProgressBarVariant() {
			const queryPercentage = this.queryProgressBarPercentage;
			if (queryPercentage > 0 && queryPercentage < 25) {
				return 'primary';
			}
			if (queryPercentage === 25) {
				return 'success';
			}
			return 'dark';
		},
		queryProgressBarPercentage() {
			if (this.query.total === 0) { return 0 } // stops the value from being NaN
			return (this.query.processed / this.query.total) * 25;
		},
		deleteProgressBarVariant() {
			const deletePercentage = this.deleteProgressBarPercentage;
			if (deletePercentage > 0 && deletePercentage < 25) {
				return 'primary';
			}
			if (deletePercentage === 25) {
				return 'success';
			}
			return 'dark';
		},
		deleteProgressBarPercentage() {
			if (this.deletion.total === 0) { return 0 } // stops the value from being NaN
			return (this.deletion.processed / this.deletion.total) * 25;
		},
		upsertProgressBarVariant() {
			const mutationPercentage = this.upsertProgressBarPercentage;
			const maxPercentage = this.deletion.enabled ? 50 : 75;
			if (mutationPercentage > 0 && mutationPercentage < maxPercentage) {
				return 'primary';
			}
			if (this.upsert.completed && this.errors.items.length === 0) {
				return 'success';
			}
			if (this.upsert.completed && this.errors.items.length > 0 && this.errors.items.length < this.upsert.total) {
				return 'warning';
			}
			if (this.upsert.completed && this.errors.items.length === this.upsert.total) {
				return 'danger';
			}
			return 'dark';
		},
		upsertProgressBarPercentage() {
			const maxPercentage = this.deletion.enabled ? 50 : 75;
			if (this.upsert.total === 0) { return 0; } // stops the value from being NaN
			return (this.upsert.processed / this.upsert.total) * maxPercentage;
		},
	},
	methods: {
		clear() {
			this.query = {
				total: 0,
				processed: 0,
				processing: false,
				completed: false
			};

			this.deletion = {
				enabled: this.deletion.enabled,
				total: 0,
				processed: 0,
				processing: false,
				completed: false,
				expectedDuration: 0,
			};

			this.upsert = {
				total: 0,
				processed: 0,
				processing: false,
				completed: false,
				expectedDuration: 0,
				insert: {
					enabled: this.upsert.insert.enabled,
					processed: 0,
					success: 0,
				},
				update: {
					enabled: this.upsert.update.enabled,
					processed: 0,
					success: 0,
				}
			};
		},

		async processFile() {
			this.clear()
			if (!this.selectedFile) return;

			const fileReader = new FileReader();
			fileReader.onload = async (event) => {
				// Query existing records
				const existingRecords = await this.listItems();
				const existingRecordMap = new Map(existingRecords.map(record => [record[this.itemKey], record])); // Change 'id' to the appropriate key field
				const batchSize = 20; // number of records to process in a batch
				const delayBetweenBatches = 1000; // delay between batches (in milliseconds)

				// Delete records
				if(this.deletion.enabled) {
					this.deletion.processing = true;
					this.deletion.total = existingRecords.length
					this.deletion.expectedDuration = Math.ceil(existingRecords.length / batchSize) * delayBetweenBatches;

					for (let i = 0; i < existingRecords.length; i += batchSize) {
						const batch = existingRecords.slice(i, i + batchSize);
						await Promise.all(batch.map(async (record, index) => {
							await this.deleteItem(record);
							this.deletion.processed += 1
						}));

						if (i + batchSize < existingRecords.length) {
							await new Promise((resolve) => setTimeout(resolve, delayBetweenBatches))
						}
					}
					this.deletion.processing = false;
					this.deletion.completed = true;

					// Clear existing record map, as we just deleted all the entries in the database, this will force all records to be created
					existingRecordMap.clear()
				}


				// Process file
				const items = JSON.parse(event.target.result);
				this.upsert.processing = true;
				this.upsert.total = items.length;
				this.upsert.expectedDuration = Math.ceil(items.length / batchSize) * delayBetweenBatches;

				// Create or update records
				for (let i = 0; i < items.length; i += batchSize) {
					const batch = items.slice(i, i + batchSize);
					await Promise.all(batch.map(async (record, index) => {
						if (this.upsert.update.enabled && existingRecordMap.has(record[this.itemKey])) {
							// Run update mutation
							await this.updateItem(record);
							this.upsert.update.processed += 1
						}
						else if(this.upsert.insert.enabled) {
							// Run create mutation
							await this.createItem(record);
							this.upsert.insert.processed += 1
						}
						this.upsert.processed += 1;
					}));

					if (i + batchSize < items.length) {
						await new Promise((resolve) => setTimeout(resolve, delayBetweenBatches))
					}
				}
				this.upsert.processing	= false;
				this.upsert.completed = true; // Add this line
			};
			fileReader.readAsText(this.selectedFile);
		},


		async listItems() {
			const query = this.queryList;
			let items = [];
			let nextToken = null;

			const resultPath = this.queryResultPath() //extracts the path to the result from the query. i.e. listApplications
			if (!resultPath) {
				console.error('Error extracting result path from query');
				return items;
			}

			do {
				this.query.processing = true;
				this.query.total += 1;

				try {
					const variables = { limit: 1000, nextToken };
					const result = await API.graphql({ query, variables });
					items = items.concat(result.data[resultPath].items);
					nextToken = result.data[resultPath].nextToken;

					// Update list progress
					this.query.processed += 1;
				}
				catch (error) {
					console.error('Error fetching records:', error);
					break;
				}
			} while (nextToken);

			this.query.processing = false;
			this.query.completed = true;
			return items;
		},

		async createItem(item) {
			// AWS AppSync does not allow you to insert a record with a createdAt or updatedAt field directly.
			delete item.createdAt; //from all
			delete item.updatedAt; //from all
			delete item.districtUsersId; // from user

			this.itemKeysToDelete.forEach((field) => {
				if (item[field] === null) {
					delete item[field];
				}
			});

			const query = this.mutationCreate
			const variables = { input: item };

			try {
				await API.graphql({ query, variables});
				this.upsert.insert.success += 1;
			}
			catch (error) {
				console.error('Error inserting item:', error);
				this.errors.items.push(`Error inserting record ${JSON.stringify(item)}: ${error}`); // Add this line
			}
		},

		async updateItem(item) {
			// AWS AppSync does not allow you to insert a record with a createdAt or updatedAt field directly.
			delete item.createdAt; //from all
			delete item.updatedAt; //from all

			this.itemKeysToDelete.forEach((field) => {
				if (item[field] === null) {
					delete item[field];
				}
			});

			const query = this.mutationUpdate
			const variables = { input: item };

			try {
				await API.graphql({ query, variables});
				this.upsert.update.success += 1;
			}
			catch (error) {
				console.error('Error updating record:', error);
				this.errors.items.push(`Error updating item ${JSON.stringify(item)}: ${error}`); // Add this line
			}
		},

		async deleteItem(item) {
			const query = this.mutationDelete
			const variables = { input: item };

			try {
				await API.graphql({ query, variables});
			}
			catch (error) {
				console.error('Error deleting record:', error);
				this.errors.items.push(`Error deleting item ${JSON.stringify(item)}: ${error}`); // Add this line
			}
		},

		/** Utility **/
		queryResultPath() {
			const queryName = this.queryList.match(/(?<=query\s)[^(]*/)[0].trim();
			return queryName.charAt(0).toLowerCase() + queryName.slice(1);
		},

		tableNameFromMutation() {
			const mutationName = this.mutationCreate.match(/(?<=mutation\s)[^(]*/)[0].trim();
			const tableName = mutationName.replace(/Create|create/, '');
			return tableName.replace(/([A-Z])/g, '-$1').toLowerCase().substring(1);
		}
	}
};
</script>

<style>
	.ps-import-errors {
		max-height: 300px;
	}
	.percentage {
		width: 6ch;
		display: inline-flex;
		justify-content: end;
	}
</style>
