
/** Application **/
export const listApplications = /* GraphQL */ `
  query ListApplications(
    $id: ID
    $filter: ModelApplicationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listApplications(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;
export const createApplication = /* GraphQL */ `
  mutation CreateApplication(
    $input: CreateApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    createApplication(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateApplication = /* GraphQL */ `
  mutation UpdateApplication(
    $input: UpdateApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    updateApplication(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteApplication = /* GraphQL */ `
  mutation DeleteApplication(
    $input: DeleteApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    deleteApplication(input: $input, condition: $condition) {
      id
    }
  }
`;


/** Bus **/
export const listBuses = /* GraphQL */ `
  query ListBuses(
    $id: ID
    $filter: ModelBusFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBuses(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;
export const createBus = /* GraphQL */ `
  mutation CreateBus(
    $input: CreateBusInput!
    $condition: ModelBusConditionInput
  ) {
    createBus(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateBus = /* GraphQL */ `
  mutation UpdateBus(
    $input: UpdateBusInput!
    $condition: ModelBusConditionInput
  ) {
    updateBus(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteBus = /* GraphQL */ `
  mutation DeleteBus(
    $input: DeleteBusInput!
    $condition: ModelBusConditionInput
  ) {
    deleteBus(input: $input, condition: $condition) {
      id
    }
  }
`;

/** District **/
export const listDistricts = /* GraphQL */ `
  query ListDistricts(
    $id: ID
    $filter: ModelDistrictFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDistricts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;
export const createDistrict = /* GraphQL */ `
  mutation CreateDistrict(
    $input: CreateDistrictInput!
    $condition: ModelDistrictConditionInput
  ) {
    createDistrict(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateDistrict = /* GraphQL */ `
  mutation UpdateDistrict(
    $input: UpdateDistrictInput!
    $condition: ModelDistrictConditionInput
  ) {
    updateDistrict(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteDistrict = /* GraphQL */ `
  mutation DeleteDistrict(
    $input: DeleteDistrictInput!
    $condition: ModelDistrictConditionInput
  ) {
    deleteDistrict(input: $input, condition: $condition) {
      id
    }
  }
`;

/** Document Category **/
export const listDocumentCategories = /* GraphQL */ `
  query ListDocumentCategories(
    $id: ID
    $filter: ModelDocumentCategoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDocumentCategories(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;
export const createDocumentCategory = /* GraphQL */ `
  mutation CreateDocumentCategory(
    $input: CreateDocumentCategoryInput!
    $condition: ModelDocumentCategoryConditionInput
  ) {
    createDocumentCategory(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateDocumentCategory = /* GraphQL */ `
  mutation UpdateDocumentCategory(
    $input: UpdateDocumentCategoryInput!
    $condition: ModelDocumentCategoryConditionInput
  ) {
    updateDocumentCategory(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteDocumentCategory = /* GraphQL */ `
  mutation DeleteDocumentCategory(
    $input: DeleteDocumentCategoryInput!
    $condition: ModelDocumentCategoryConditionInput
  ) {
    deleteDocumentCategory(input: $input, condition: $condition) {
      id
    }
  }
`;

/** Document **/
export const listDocuments = /* GraphQL */ `
  query ListDocuments(
    $id: ID
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDocuments(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;
export const createDocument = /* GraphQL */ `
  mutation CreateDocument(
    $input: CreateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    createDocument(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateDocument = /* GraphQL */ `
  mutation UpdateDocument(
    $input: UpdateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    updateDocument(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteDocument = /* GraphQL */ `
  mutation DeleteDocument(
    $input: DeleteDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    deleteDocument(input: $input, condition: $condition) {
      id
    }
  }
`;

/** Ensemble **/
export const listEnsembles = /* GraphQL */ `
  query ListEnsembles(
    $id: ID
    $filter: ModelEnsembleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEnsembles(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;
export const createEnsemble = /* GraphQL */ `
  mutation CreateEnsemble(
    $input: CreateEnsembleInput!
    $condition: ModelEnsembleConditionInput
  ) {
    createEnsemble(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateEnsemble = /* GraphQL */ `
  mutation UpdateEnsemble(
    $input: UpdateEnsembleInput!
    $condition: ModelEnsembleConditionInput
  ) {
    updateEnsemble(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteEnsemble = /* GraphQL */ `
  mutation DeleteEnsemble(
    $input: DeleteEnsembleInput!
    $condition: ModelEnsembleConditionInput
  ) {
    deleteEnsemble(input: $input, condition: $condition) {
      id
    }
  }
`;

/** Ensemble Instruments **/
export const listEnsembleInstruments = /* GraphQL */ `
  query ListEnsembleInstruments(
    $filter: ModelEnsembleInstrumentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnsembleInstruments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;
export const createEnsembleInstruments = /* GraphQL */ `
  mutation CreateEnsembleInstruments(
    $input: CreateEnsembleInstrumentsInput!
    $condition: ModelEnsembleInstrumentsConditionInput
  ) {
    createEnsembleInstruments(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateEnsembleInstruments = /* GraphQL */ `
  mutation UpdateEnsembleInstruments(
    $input: UpdateEnsembleInstrumentsInput!
    $condition: ModelEnsembleInstrumentsConditionInput
  ) {
    updateEnsembleInstruments(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteEnsembleInstruments = /* GraphQL */ `
  mutation DeleteEnsembleInstruments(
    $input: DeleteEnsembleInstrumentsInput!
    $condition: ModelEnsembleInstrumentsConditionInput
  ) {
    deleteEnsembleInstruments(input: $input, condition: $condition) {
      id
    }
  }
`;

/** Festival **/
export const listFestivals = /* GraphQL */ `
  query ListFestivals(
    $id: ID
    $filter: ModelFestivalFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFestivals(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;
export const createFestival = /* GraphQL */ `
  mutation CreateFestival(
    $input: CreateFestivalInput!
    $condition: ModelFestivalConditionInput
  ) {
    createFestival(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateFestival = /* GraphQL */ `
  mutation UpdateFestival(
    $input: UpdateFestivalInput!
    $condition: ModelFestivalConditionInput
  ) {
    updateFestival(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteFestival = /* GraphQL */ `
  mutation DeleteFestival(
    $input: DeleteFestivalInput!
    $condition: ModelFestivalConditionInput
  ) {
    deleteFestival(input: $input, condition: $condition) {
      id
    }
  }
`;

/** Form **/
export const listForms = /* GraphQL */ `
  query ListForms(
    $id: ID
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listForms(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;
export const createForm = /* GraphQL */ `
  mutation CreateForm(
    $input: CreateFormInput!
    $condition: ModelFormConditionInput
  ) {
    createForm(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateForm = /* GraphQL */ `
  mutation UpdateForm(
    $input: UpdateFormInput!
    $condition: ModelFormConditionInput
  ) {
    updateForm(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteForm = /* GraphQL */ `
  mutation DeleteForm(
    $input: DeleteFormInput!
    $condition: ModelFormConditionInput
  ) {
    deleteForm(input: $input, condition: $condition) {
      id
    }
  }
`;

/** Instrument **/
export const listInstruments = /* GraphQL */ `
  query ListInstruments(
    $id: ID
    $filter: ModelInstrumentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInstruments(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;
export const createInstrument = /* GraphQL */ `
  mutation CreateInstrument(
    $input: CreateInstrumentInput!
    $condition: ModelInstrumentConditionInput
  ) {
    createInstrument(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateInstrument = /* GraphQL */ `
  mutation UpdateInstrument(
    $input: UpdateInstrumentInput!
    $condition: ModelInstrumentConditionInput
  ) {
    updateInstrument(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteInstrument = /* GraphQL */ `
  mutation DeleteInstrument(
    $input: DeleteInstrumentInput!
    $condition: ModelInstrumentConditionInput
  ) {
    deleteInstrument(input: $input, condition: $condition) {
      id
    }
  }
`;

/** Invoice **/
export const listInvoices = /* GraphQL */ `
  query ListInvoices(
    $id: ID
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInvoices(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;
export const createInvoice = /* GraphQL */ `
  mutation CreateInvoice(
    $input: CreateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    createInvoice(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateInvoice = /* GraphQL */ `
  mutation UpdateInvoice(
    $input: UpdateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    updateInvoice(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteInvoice = /* GraphQL */ `
  mutation DeleteInvoice(
    $input: DeleteInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    deleteInvoice(input: $input, condition: $condition) {
      id
    }
  }
`;

/** Purchase Order **/
/*export const listPurchaseOrders = /!* GraphQL *!/ `
  query ListPurchaseOrders(
    $filter: ModelPurchaseOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPurchaseOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
      }
      nextToken
    }
  }
`;
export const createPurchaseOrder = /!* GraphQL *!/ `
  mutation CreatePurchaseOrder(
    $input: CreatePurchaseOrderInput!
    $condition: ModelPurchaseOrderConditionInput
  ) {
    createPurchaseOrder(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updatePurchaseOrder = /!* GraphQL *!/ `
  mutation UpdatePurchaseOrder(
    $input: UpdatePurchaseOrderInput!
    $condition: ModelPurchaseOrderConditionInput
  ) {
    updatePurchaseOrder(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deletePurchaseOrder = /!* GraphQL *!/ `
  mutation DeletePurchaseOrder(
    $input: DeletePurchaseOrderInput!
    $condition: ModelPurchaseOrderConditionInput
  ) {
    deletePurchaseOrder(input: $input, condition: $condition) {
      id
    }
  }
`;*/

/** Payment **/
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
      }
      nextToken
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      id
    }
  }
`;

/** School **/
export const listSchools = /* GraphQL */ `
  query ListSchools(
    $id: ID
    $filter: ModelSchoolFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSchools(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;
export const createSchool = /* GraphQL */ `
  mutation CreateSchool(
    $input: CreateSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    createSchool(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateSchool = /* GraphQL */ `
  mutation UpdateSchool(
    $input: UpdateSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    updateSchool(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteSchool = /* GraphQL */ `
  mutation DeleteSchool(
    $input: DeleteSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    deleteSchool(input: $input, condition: $condition) {
      id
    }
  }
`;

/** Selection **/
export const listSelections = /* GraphQL */ `
  query ListSelections(
    $id: ID
    $filter: ModelSelectionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSelections(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;
export const createSelection = /* GraphQL */ `
  mutation CreateSelection(
    $input: CreateSelectionInput!
    $condition: ModelSelectionConditionInput
  ) {
    createSelection(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateSelection = /* GraphQL */ `
  mutation UpdateSelection(
    $input: UpdateSelectionInput!
    $condition: ModelSelectionConditionInput
  ) {
    updateSelection(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteSelection = /* GraphQL */ `
  mutation DeleteSelection(
    $input: DeleteSelectionInput!
    $condition: ModelSelectionConditionInput
  ) {
    deleteSelection(input: $input, condition: $condition) {
      id
    }
  }
`;

/** Setting **/
export const listSettings = /* GraphQL */ `
  query ListSettings(
    $key: String
    $filter: ModelSettingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSettings(
      key: $key
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        key
      }
      nextToken
    }
  }
`;
export const createSetting = /* GraphQL */ `
  mutation CreateSetting(
    $input: CreateSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    createSetting(input: $input, condition: $condition) {
      key
    }
  }
`;
export const updateSetting = /* GraphQL */ `
  mutation UpdateSetting(
    $input: UpdateSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    updateSetting(input: $input, condition: $condition) {
      key
    }
  }
`;
export const deleteSetting = /* GraphQL */ `
  mutation DeleteSetting(
    $input: DeleteSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    deleteSetting(input: $input, condition: $condition) {
      key
    }
  }
`;

/** Student **/
export const listStudents = /* GraphQL */ `
  query ListStudents(
    $id: ID
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStudents(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;
export const createStudent = /* GraphQL */ `
  mutation CreateStudent(
    $input: CreateStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    createStudent(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateStudent = /* GraphQL */ `
  mutation UpdateStudent(
    $input: UpdateStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    updateStudent(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteStudent = /* GraphQL */ `
  mutation DeleteStudent(
    $input: DeleteStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    deleteStudent(input: $input, condition: $condition) {
      id
    }
  }
`;

/** Ticket **/
export const listTickets = /* GraphQL */ `
  query ListTickets(
    $id: ID
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTickets(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;
export const createTicket = /* GraphQL */ `
  mutation CreateTicket(
    $input: CreateTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    createTicket(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateTicket = /* GraphQL */ `
  mutation UpdateTicket(
    $input: UpdateTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    updateTicket(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteTicket = /* GraphQL */ `
  mutation DeleteTicket(
    $input: DeleteTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    deleteTicket(input: $input, condition: $condition) {
      id
    }
  }
`;

/** Ticket Reply **/
export const listTicketReplies = /* GraphQL */ `
  query ListTicketReplies(
    $id: ID
    $filter: ModelTicketReplyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTicketReplies(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;
export const createTicketReply = /* GraphQL */ `
  mutation CreateTicketReply(
    $input: CreateTicketReplyInput!
    $condition: ModelTicketReplyConditionInput
  ) {
    createTicketReply(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateTicketReply = /* GraphQL */ `
  mutation UpdateTicketReply(
    $input: UpdateTicketReplyInput!
    $condition: ModelTicketReplyConditionInput
  ) {
    updateTicketReply(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteTicketReply = /* GraphQL */ `
  mutation DeleteTicketReply(
    $input: DeleteTicketReplyInput!
    $condition: ModelTicketReplyConditionInput
  ) {
    deleteTicketReply(input: $input, condition: $condition) {
      id
    }
  }
`;

/** User **/
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $id: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
    }
  }
`;

/** User Schools **/
export const listUserSchools = /* GraphQL */ `
  query ListUserSchools(
    $filter: ModelUserSchoolsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserSchools(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
      }
      nextToken
    }
  }
`;
export const createUserSchools = /* GraphQL */ `
  mutation CreateUserSchools(
    $input: CreateUserSchoolsInput!
    $condition: ModelUserSchoolsConditionInput
  ) {
    createUserSchools(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateUserSchools = /* GraphQL */ `
  mutation UpdateUserSchools(
    $input: UpdateUserSchoolsInput!
    $condition: ModelUserSchoolsConditionInput
  ) {
    updateUserSchools(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteUserSchools = /* GraphQL */ `
  mutation DeleteUserSchools(
    $input: DeleteUserSchoolsInput!
    $condition: ModelUserSchoolsConditionInput
  ) {
    deleteUserSchools(input: $input, condition: $condition) {
      id
    }
  }
`;

/** Zone **/
export const listZones = /* GraphQL */ `
  query ListZones(
    $id: ID
    $filter: ModelZoneFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listZones(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;
export const createZone = /* GraphQL */ `
  mutation CreateZone(
    $input: CreateZoneInput!
    $condition: ModelZoneConditionInput
  ) {
    createZone(input: $input, condition: $condition) {
      id
      name
      counties
      state {
        enabled
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateZone = /* GraphQL */ `
  mutation UpdateZone(
    $input: UpdateZoneInput!
    $condition: ModelZoneConditionInput
  ) {
    updateZone(input: $input, condition: $condition) {
      id
      name
      counties
      state {
        enabled
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteZone = /* GraphQL */ `
  mutation DeleteZone(
    $input: DeleteZoneInput!
    $condition: ModelZoneConditionInput
  ) {
    deleteZone(input: $input, condition: $condition) {
      id
    }
  }
`;
